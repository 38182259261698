import {NavLink, useNavigate, useParams  } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import ProductService from "../services/ProductService";
import AssistantService from "../services/AssistantService";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import ThumbUpBlank  from "../images/thumb-up-blank.png";
import ThumbUp from "../images/thumb-up.png";
import ThumbDown  from "../images/thumb-down.png";
import Delete  from "../images/delete.png";
import InfoIcon  from "../images/info.png";
import JobDesc  from "../images/job-description.png";
import CloseIcon  from "../images/close.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';

function IncompletedTask() {
  const { appslug } = useParams();
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [outputCount, setOutputCount] = useState("");
  const [totalProducts, setTotalProducts] = useState("");
  const [productList, setProductList] = useState("");
  const [toggleState, setToggleState] = useState({});
  const [plusMinus, setPlusMinus] = useState(false);
  const [outputList, setOutputList] = useState("");
  const [additionalData, setAdditionalData] = useState({});
  const [thumbUped, setThumbUped] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [searchISBN, setSearchISBN] = useState("");
  const [showDescModal, setShowDescModal] = useState(false);
  const [jobDescription, setJobDescription] = useState(false);
  const [q1Question, setQ1Question] = useState();
  const [productDescription, setProductDescription] = useState();
  const [questionsData, setQuestionsData] = useState([]);
  const [assistantID, setAssistantID] = useState(false);
  const [q1Rows, setQ1Rows] = useState([
    { id: 1, heading: '', description: '' }
  ]);
  //console.log("questionsData", questionsData);
  const handleSearchChange = (event) => {
    // Update the state with the new input value
    setSearchISBN(event.target.value);
    
  };


  useEffect(() => {
    setLoadingMsg(true);
    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
        setAssistantID(assistresponse.assistantID);
        ProductService.exampleList(assistresponse.assistantID).then(
          (response) => {
            //console.log("EX LIST", response.data);
            const newOutputIds = response.data.map(item => item.id);
            setThumbUped(prevData => {
                // Add each output_id to the state
                const newData = { ...prevData };
                newOutputIds.forEach(outputId => {
                    newData[outputId] = outputId;
                });
                return newData;
            });
          }
        ). catch(
          (error) => {
            console.error("Error fetching item exampleList:", error);
          }
        )
        ProductService.productList(assistresponse.assistantID, 'incomplete', "", currentPage).then(
          (response) => {
            console.log("response.data.products", response.data.products)
            setOutputCount(response.data.output_count);
            setTotalProducts(response.data.total_products);
            setProductList(response.data.products);
            setTotalPages(response.data.total_pages);
             setLoadingMsg(false);
            /*response.data.products.forEach(book => {
              fetchAdditionalData(assistresponse.assistantID, book.product_isbn);
            });*/
          }
        ). catch(
          (error) => {
            console.error("Error fetching item productList:", error);
             setLoadingMsg(false);
          }
        )
    }
    )
  },[appslug, currentPage])
 
  const deleteOutput = (outputId) => {
    const confirmed = window.confirm("Are you sure you want to delete this item?")
    if (confirmed) {
      ProductService.deleteOutput(outputId).then(
        (response) => {
          document.getElementById("outputitem_"+outputId).style.display = 'none';
          //console.log(response);
        }
      ).catch(
          (error) => {
              console.error("Error deleting item:", error);
          }
      );
    }
  }

  const exampleOutput = (updown, outputId) => {
    var confirmed = window.confirm('Are you sure you want to do it?');
    if(confirmed){
      ProductService.exampleOutput(assistantID, updown, outputId).then(
        (response) => {
          ///console.log(response);
          {updown=='up' ? 
          setThumbUped(prevData => ({ ...prevData, [outputId]: outputId })) 
          : 
          setThumbUped(prevData => {
              // Remove the specified outputId
              const { [outputId]: _, ...newData } = prevData;
              return newData;
          });
        }
        }
      ).catch(
          (error) => {
              console.error("Error deleting item:", error);
          }
      );
    }
    
  }

  const productSearch = () => {

    ProductService.productList(assistantID, "incompletesearch", searchISBN, 1).then(
      (response) => {

        setProductList(response.data.products);
        setTotalPages(response.data.total_pages);
        //response.data.products.forEach(book => {
          //fetchAdditionalData(book.product_isbn);
        //})
      }
    ).catch(
      (error) => {
        console.log(error)
      }
    )
  }

  const fetchAdditionalData = async (assistantID, isbn) => {
    try {
      ProductService.outputList(assistantID, isbn, "incomplete").then(
      (response) => {
        //console.log(response.data);
        setAdditionalData(prevData => ({ ...prevData, [isbn]: response.data }));
      },
      (error) => {
        console.log(error);
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const showOutputs = (isbn) => {
    
    setToggleState(prevState => ({
      ...prevState,
      [isbn]: !prevState[isbn]
    }));
  }

  const viewProductDescription = (prodDesc) => {
    setProductDescription(prodDesc);
    setShowDescModal(true);
  }
  const viewJobDescription = (outputid, outputidd) => {
    const questionsDataa = JSON.parse(outputidd);
    setQuestionsData(questionsDataa);


    const promptData = JSON.parse(outputid);
    setQ1Question(promptData[0].question);
    const questionData = promptData[0].data;
    setQ1Rows(questionData)
    //setJobDescription(outputid)

    


    handleOpenModal();
  }

  const handleCloseProdDesc = () => {
    setShowDescModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstClick = () => {
    setCurrentPage(1);
  };

  const handleLastClick = () => {
    setCurrentPage(totalPages);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 7;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalPages, endPage + (halfMaxPagesToShow - (currentPage - 1)));
    }

    if (currentPage + halfMaxPagesToShow > totalPages) {
      startPage = Math.max(1, startPage - ((currentPage + halfMaxPagesToShow) - totalPages));
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
   

  const nl2br = (text) => {
    return text.split('\n').map((str, index) => (
      <>
        {str}
        <br />
      </>
    ));
  };
  return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                 

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('incompletedtasks')}</h1>
                      <div className="breadcrumb">
                        {t('incompletedtasks')}
                      </div>
                    </div>
                    <CreditBar /><TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-7 px-4 ">
                    <p>{t('completedtaskpagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-7">
                        <div className="">
                          {outputCount} {t('generatedoutputcount')}, {totalProducts-outputCount} {t('remainingoutputcount')}
                        </div>
                      </div>
                      <div className="col-2 navigateButton">
                        <div className="">
                          <NavLink to={"/"+appslug+"/completed-task"}><button className="btn btn-md fm-btn me-md-2" type="button" >{t('completedtask')}</button> </NavLink> 
                              
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="input-group mb-3">
                          <input type="text" onChange={handleSearchChange} className="form-control" placeholder={t('search')} aria-label="" aria-describedby="" />
                          <button onClick={() => productSearch()} className="btn fm-btn" type="button">{t('search')}</button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      
                         <div className="col-12 fm-content-box p-4">
                          <div className="col-12 table-responsive mt-4 fm-table">
                              <table className="table table-hover align-top">
                               <tbody>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col">SR.</th>
                                    <th scope="col">{t('isbn')}</th>
                                    <th scope="col" className="col-2">{t('title')}</th>
                                    <th scope="col">{t('description')}</th>
                                    </tr>
                                    {loadingMsg ? 
                                    (
                                    <tr><td align="center" colspan="5">{t('loadingdata')}</td></tr>
                                    )
                                     
                                    : ''}
                                  {productList ? 
                                    productList.map((val, key) =>{
                                      return (
                                        <tr>
                                          <td valign="top"></td>
                                          <td>{val.serial_number}</td>
                                          <td>{val.product_isbn}</td>
                                          <td>{val.product_name}</td>
                                          <td>{val.product_description.replace(/<P>/gi, '').replace(/<[^>]*>/g, '').replace(/<!--.*?-->|<\/?p>/g, '').trim()}</td>
                                        </tr>
                                        )
                                    })
                                   : ''}
                                                                                            

                                   </tbody>
                                   <tr>
                                    <td colspan="5">
                                      <button className="paging" onClick={handleFirstClick} disabled={currentPage === 1}>First</button>
                                      <button className="paging" onClick={handlePreviousClick} disabled={currentPage === 1}>Previous</button>
                                      {generatePageNumbers().map(page => (
                                        <button  className={currentPage === page ? 'currpaging' : 'paging'}
                                          key={page}
                                          onClick={() => handlePageClick(page)}
                                          
                                        >
                                          {page}
                                        </button>
                                      ))}
                                      <button className="paging" onClick={handleNextClick} disabled={currentPage === totalPages}>Next</button>
                                      <button className="paging" onClick={handleLastClick} disabled={currentPage === totalPages}>Last</button>
                                  
                                    </td>
                                   </tr>
                                </table>

                            </div>
                            
                            
                            
                         </div>
                     </div>
                     
                     
                  </div>
                  
                  
                </main>
              </div>
            </div>
          
        </div>
        )
}
export default IncompletedTask;