import axios from "axios";

class PIMServices {
    
    

    getPIMConfig(orgId){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {organization_id: orgId},
            url: process.env.REACT_APP_API_SERVER_URL + "getpimconfig",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    savePIMConfig(orgId, config){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {organization_id: orgId, pim_url: config.pim_url, pim_username: config.pim_username, pim_password: config.pim_password, pim_structure: config.pim_structure},
            url: process.env.REACT_APP_API_SERVER_URL + "savepimconfig",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    setPIMFirstImportStart(orgId){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {organization_id: orgId},
            url: process.env.REACT_APP_API_SERVER_URL + "setpimfirstimportstart",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    SetPIMFrequency(orgID, frequency, days, time, attrIDs){
            const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"organization_id": orgID, "frequency": frequency, "days": days, "time": time, "attribute_ids": attrIDs},
            url: process.env.REACT_APP_API_SERVER_URL + "setpimfrequency",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    GetPIMFrequency(orgID){
            const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"organization_id": orgID},
            url: process.env.REACT_APP_API_SERVER_URL + "getpimfrequency",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
    PIMAttributes(orgID) {
        const data = {
            organization_id: orgID
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "pimattributes",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    resumeStopImport(resumestop, orgID) {
        const data = {
            organization_id: orgID,
            first_import: resumestop
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "resumestopfirstimport",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    
      
}

export default new PIMServices();