import axios from "axios";

class ProductService {
    
    
    productList(assistantID, action, serachISBN, page) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "products?page="+page+"&action="+action+"&assistant_id="+assistantID+"&searchisbn="+serachISBN,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    ProductStats(assistantID) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "productsstats?assistant_id="+assistantID,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }


    outputList(assistantID,  isbn, action) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "outputs?isbn="+isbn+"&assistant_id="+assistantID+"&action="+action,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    exampleList(assistantID) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "examplelist?assistant_id="+assistantID,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    deleteOutput(outputId){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"id":outputId},
            url: process.env.REACT_APP_API_SERVER_URL + "deleteoutput",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    exampleOutput(assistantID, updown, outputId){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"id":outputId, "updown": updown, "assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "exampleoutput",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    addSchedule(orgID, assistantID, selectedDate, isbnlist, schedulenow){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"isbns": isbnlist, "assistant_id": assistantID, "cron_start_date": selectedDate, "organization_id": orgID, "schedulenow": schedulenow},
            url: process.env.REACT_APP_API_SERVER_URL + "addschedule",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    

}

export default new ProductService();