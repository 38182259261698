import axios from "axios";

class AssistantService {
    
    assistantsList(orgID) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "assistantslist?organization_id="+orgID,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    getAssistantIDBySlug(slug){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"slug":slug},
            url: process.env.REACT_APP_API_SERVER_URL + "assistantidbyslug",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
    SetSEOFrequency(orgID, assistantID, selectedDate, attributeIDs, schedulenow, includeall, totalProducts){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"organization_id": orgID, "assistant_id": assistantID, "cron_start_date": selectedDate, "attribute_ids": attributeIDs, "schedulenow": schedulenow, "include_all": includeall, "total_products": totalProducts},
            url: process.env.REACT_APP_API_SERVER_URL + "setseofrequency",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    SetSEOCriteriaAndSchedule(orgID, assistantID, time, attributeIDs){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"organization_id": orgID, "assistant_id": assistantID, "execution_time": time, "attribute_ids": attributeIDs},
            url: process.env.REACT_APP_API_SERVER_URL + "setseocriteriaandschedule",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
    GetSEOCriteriaAndSchedule(assistantID){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "getseocriteriaandschedule",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    GetSEOFrequency(assistantID){
            const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "getseofrequency",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
    GetAttributeGroupValuesById(attributeId) {
        const data = {
            attribute_id: attributeId
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "attributegroupvaluesbyid",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    GetAttributeGroupNameById(groupID) {
        const data = {
            id: groupID
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "attributegroupnamebyid",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }


    GetAttributeValueSEOStats(attributeId, assistantID) {
        /*const data = {
            attribute_id: attributeId
        }*/
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: {"attribute_id": attributeId, "assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "attributevalueseostats",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }
    GetWorkPlanList(assistantID) {
       
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: {"assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "workplanlist",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    GetAttributeNamesByIDs(attributeIDs) {
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: {"attribute_id": attributeIDs},
            url: process.env.REACT_APP_API_SERVER_URL + "attributesnamebyids",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    GetAttributeNamesByID(attributeID) {
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: {"id": attributeID},
            url: process.env.REACT_APP_API_SERVER_URL + "attributesnamebyid",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    StopResumeWorkPlan(workPlanID, stopresume) {
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: {"id": workPlanID, "status": stopresume},
            url: process.env.REACT_APP_API_SERVER_URL + "stopresumeworkplan",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    
}

export default new AssistantService();