import {NavLink, useNavigate, useParams  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AuthService from "../services/AuthService";
import AssistantService from "../services/AssistantService";
import PromptService from "../services/PromptService";
import UserService from "../services/UserService";
import "../styles/bootstrap.min.css";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import JobDescriptionQuestion from "./JobDescriptionQuestion";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import Notification  from "../images/notifications_none.png";
import CrossCircle  from "../images/cross-circle.png";
import EditIcon  from "../images/edit.png";
import Unlock  from "../images/unlock.png";
import Lock  from "../images/lock.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';
import CloseCircle  from "../images/cross-circle.png";

function JobInstruction() {
  const { appslug } = useParams();
  const [trigger, setTrigger] = useState(false);
  const [editSystemQuestionKey, setEditSystemQuestionKey] = useState("");
  const [editingSystemQuestion, setEditSystemQuestion] = useState(false);
  const [editUserQuestionKey, setEditUserQuestionKey] = useState("");
  const [editingUserQuestion, setEditUserQuestion] = useState(false);
  const [editingUserQuestionColumn, setEditUserQuestionColumn] = useState(false);
  document.body.style.backgroundColor = '#FFFFFF';
  const [assistantID, setAssistantID] = useState();
  let navigate  = useNavigate();
  const [systemDefined, setSystemDefined] = useState();
  const [userDefined, setUserDefined] = useState();
  const [questionOneDefined, setQuestionOneDefined] = useState();
  const [submitUserOneMsg, setSubmitUserOneMsg] = useState();
  const [submitSystemMsg, setSubmitSystemMsg] = useState();

  const [newSystemDefinedQA, setNewSystemDefinedQA] = useState({
    q: '',
    a: ''
  });
   const [newUserDefinedQA, setNewUserDefinedQA] = useState({
    q: '',
    help: ''
  });
  const { t } = useTranslation();
  

  useEffect(() => {
    const userRole = window.sessionStorage.getItem('userrole');
    if(userRole=='1'){
      navigate("/overview")
    }
    AssistantService.getAssistantIDBySlug(appslug).then(
    (assistresponse) => {
      setAssistantID(assistresponse.assistantID);
      PromptService.getPromptByType(assistresponse.assistantID, "systemdefined").then(
        (response) => {
          const systemDefinedJson = JSON.parse(response.prompt.prompt)
          setSystemDefined(systemDefinedJson);
        }
      ). catch(
        (error) => {
        console.log("Error fetching prompt", error);
        }
      )
      PromptService.getPromptByType(assistresponse.assistantID, "userdefined").then(
        (response) => {
          const userDefinedJson = JSON.parse(response.prompt.prompt)
          //console.log("userDefinedJson", userDefinedJson);
          setUserDefined(userDefinedJson);
        }
      ). catch(
        (error) => {
        console.log("Error fetching prompt", error);
        }
      )
      PromptService.getPromptByType(assistresponse.assistantID, "questionone").then(
        (response) => {
          const questiononeDefinedJson = JSON.parse(response.prompt.prompt)
          //console.log("questiononeDefinedJson", questiononeDefinedJson);
          setQuestionOneDefined(questiononeDefinedJson);
        }
      ). catch(
        (error) => {
        console.log("Error fetching prompt", error);
        }
      )
    })
    
  }, [trigger, appslug])
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(editingSystemQuestion){
      if(newUserDefinedQA.q){
        setSubmitSystemMsg("");
        PromptService.editSystemDefinedPrompt(assistantID, newSystemDefinedQA, editSystemQuestionKey, ).then(
        (response) => {
            setTrigger(prev => !prev);
            setNewSystemDefinedQA({
              q: '',
              a: ''
            })
            setEditSystemQuestion(false);
            setEditSystemQuestionKey("");
          }
        ).catch(
          (error) => {
            console.log("Error editing prompt". error)
          }
        )
      } else {
        setSubmitSystemMsg(t("systemquestionvalidation"))
      }
    } else {
      if(newUserDefinedQA.q){
        PromptService.addSystemDefinedPrompt(assistantID, newSystemDefinedQA).then(
          (response) => {
            setTrigger(prev => !prev);
            setNewSystemDefinedQA({
              q: '',
              a: ''
            })
          }
        ).catch(
          (error) => {
            console.log("Error adding prompt". error)
          }
        )
      } else {
        setSubmitSystemMsg(t("systemquestionvalidation"))
      }
    }
    

  }
  const handleSubmitUserOne = (event) => {
    event.preventDefault();
    console.log(assistantID, newUserDefinedQA, editUserQuestionKey, editingUserQuestionColumn);
    if(editingUserQuestion == true){
      if(newUserDefinedQA.q){
        setSubmitUserOneMsg("");
        PromptService.editUserOneDefinedPrompt(assistantID, newUserDefinedQA, editUserQuestionKey, editingUserQuestionColumn).then(
        (response) => {
            setTrigger(prev => !prev);
            setNewUserDefinedQA({
              q: '',
              help: ''
            })
            setEditUserQuestion(false);
            setEditUserQuestionKey("");
          }
        ).catch(
          (error) => {
            console.log("Error editing prompt". error)
          }
        )
      } else {
        setSubmitUserOneMsg(t('useronequestionvalidation'));
      }
    } else {
      if(newUserDefinedQA.q){
        setSubmitUserOneMsg("");
        PromptService.addUserOneDefinedPrompt(assistantID, newUserDefinedQA, editingUserQuestionColumn).then(
        (response) => {
            setTrigger(prev => !prev);
            setNewUserDefinedQA({
              q: '',
              help: ''
            })
            setEditUserQuestion(false);
            //setEditUserQuestionKey("");
          }
        ).catch(
          (error) => {
            console.log("Error editing prompt". error)
          }
        )
      } else {
        setSubmitUserOneMsg(t('useronequestionvalidation'));
      }
      
    }
  }
  const deleteSystemQuestion = (questionKey) => {
    var confirmed  = window.confirm("Are you sure you want to delete it?")
    if(confirmed){
      PromptService.deleteSystemDefinedPrompt(assistantID, questionKey).then(
      (response) => {
          setTrigger(prev => !prev);
        }
      ).catch(
        (error) => {
          console.log("Error delete prompt". error)
        }
      )
    }
    
  }

  const deleteUserOneQuestion = (questionKey, column) => {
    var confirmed  = window.confirm("Are you sure you want to delete it?")
    if(confirmed){
      PromptService.deleteUserOnePrompt(assistantID, questionKey, column).then(
      (response) => {
          setTrigger(prev => !prev);
        }
      ).catch(
        (error) => {
          console.log("Error delete prompt". error)
        }
      )
    }
    
  }
  
  const editUserQuestion = (values, help, questionKey, column) => {
      //console.log(values);
      setEditUserQuestionKey(questionKey)
      setEditUserQuestion(true)
      setEditUserQuestionColumn(column)
      setNewUserDefinedQA({
        q: values,
        help: help
      })
  }
  const editSystemQuestion = (values, questionKey) => {
      //console.log(values);
      setEditSystemQuestionKey(questionKey)
      setEditSystemQuestion(true)
      setNewSystemDefinedQA({
        q: values.q,
        a: values.a
      })
  }
  const handleChange = (event) => {
    if(event.keyCode=='13'){
      handleSubmit(event);
    }
    setNewSystemDefinedQA({
        ...newSystemDefinedQA,
        [event.target.name]: event.target.value
    });
  };
  const handleUserOneColumn = (event) => {
     setEditUserQuestionColumn(event.target.value)
  }
  const handleUserOneChange = (event) => {
    //console.log(event.target.value)
    /*if(event.keyCode=='13'){
      handleSubmit(event);
    }
    setNewUserDefinedQA({['q']: event.target.value})*/
    if(event.keyCode=='13'){
      handleSubmit(event);
    }
    setNewUserDefinedQA({
        ...newUserDefinedQA,
        [event.target.name]: event.target.value
    });
  };

  return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('jobinstruction')}</h1>
                    </div>
                    <TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0  mb-3">
                    <div className="col-md-3 col-lg-12 px-4 ">
                    <p>{t('jobinstructionpagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid mt-4">
                    <div className="row">
                        <div className="col-12 fm-content-box p-4">
                          <div className="col-12">
                          <h3>{t('addinstructionforassistant')}</h3>
                          </div>
                          <div className="col-12">
                            <form  onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-3">
                              <input type="text"  name="q" value={newSystemDefinedQA.q} onChange={handleChange}  className="form-control" placeholder="" aria-label="" aria-describedby="" />
                              </div>
                              <div className="col-9">
                                <div className="input-group mb-3">
                                  <input type="text" name="a" value={newSystemDefinedQA.a}  onChange={handleChange}  className="form-control" placeholder="" aria-label="" aria-describedby="" />
                                  <button className="btn fm-btn" type="submit">{editingSystemQuestion ? t('edit') : t('add')}</button>
                                </div>
                                
                              </div>
                              <div className="error">{submitSystemMsg ? submitSystemMsg : ""}</div>
                            </div>
                            </form>
                          </div>
                          
                          {systemDefined ? 
                            systemDefined.slice().reverse().map((value, key) => {
                            const displayIndex = systemDefined.length - 1 - key;
                            return <>
                            <div className="col-12 mt-3 align-middle">
                              <div className="col-lg-12 fm-card-tool p-2 align-middle d-flex flex-wrap flex-md-nowrap align-items-center">
                                  <img src={Lock} />
                                    <div className="col-lg-10 col-md-9 col-sm-8 col-xs-12">
                                      <h1>{value.q}</h1>
                                        <p>{value.a}</p>
                                    </div>
                                    <div className="col-lg-1 col-xs-12 ms-auto" align="right">
                                      <img src={EditIcon} onClick={() => editSystemQuestion(value, displayIndex)} className="close-btn" title={t('edit')} alt={t('edit')}  />
                                      <img src={CrossCircle} onClick={() => deleteSystemQuestion(displayIndex)} className="close-btn" title={t('delete')} alt={t('delete')}  />
                                  </div>

                                </div>
                            </div>
                            </>
                            })
                          : ''}
                          
                          
                          
                          
                       </div>
                    </div>
                   
                   <div className="row mt-5 mb-5">
                       
                       <div className="col-12 fm-content-box p-4">
                          <div className="col-12">
                          <h3>{t('addinstructionforassistant')}</h3>
                          </div>
                          <div className="col-12">
                            <form  onSubmit={handleSubmitUserOne}>
                            <div className="row">
                              <div className="col-2">
                                <div className="input-group mb-3">
                                  <select onChange={handleUserOneColumn}  className="form-control" >
                                    <option>Velg kolonne</option>
                                    <option value="onecolumn" selected={editingUserQuestionColumn=='onecolumn' ? "selected" : ""}>Én kolonne</option>
                                    <option  value="twocolumn" selected={editingUserQuestionColumn=='twocolumn' ? "selected" : ""}>To kolonner</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-10">
                                <div className="input-group mb-3">
                                  
                                  <input type="text" placeholder={t('question')} name="q" value={newUserDefinedQA.q}  onChange={handleUserOneChange}  className="form-control"  aria-label="" aria-describedby="" />
                                  <input type="text" placeholder={t('help')} name="help" value={newUserDefinedQA.help}  onChange={handleUserOneChange}  className="form-control" aria-label="" aria-describedby="" />
                                  <button className="btn fm-btn" type="submit">{editingUserQuestion ? t('edit') : t('add')}</button>
                                </div>
                                <div className="error">{submitUserOneMsg ? submitUserOneMsg : ""}</div>
                              </div>
                              
                            </div>
                            </form>
                          </div>
                          
                          
                         
                          <div className="col-12 mt-3 align-middle">
                            {questionOneDefined ? 
                              questionOneDefined.slice().reverse().map((value, key) => {
                              const displayIndex = questionOneDefined.length - 1 - key;
                              return <>
                              <div className="col-12 mt-3 align-middle">
                                <div className="col-lg-12 fm-card-tool p-2 align-middle d-flex flex-wrap flex-md-nowrap align-items-center">
                                    <img src={Unlock} />
                                      <div className="col-lg-10 col-md-9 col-sm-8 col-xs-12">
                                        <h1>{value.question}</h1>
                                        {value.help ? <p><i class="bi bi-info-circle"></i> {value.help}</p> : ""}
                                      </div>
                                      <div className="col-lg-1 col-xs-12 ms-auto" align="right">
                                        <img src={EditIcon} onClick={() => editUserQuestion(value.question, value.help, displayIndex, 'twocolumn')} className="close-btn" title={t('edit')} alt={t('edit')}  />
                                        <img src={CrossCircle} onClick={() => deleteUserOneQuestion(displayIndex, 'twocolumn')} className="close-btn" title={t('delete')} alt={t('delete')}  />
                                    </div>
                                  </div>
                              </div>
                              
                              </>
                              })
                            : ''}
                            {userDefined ? 
                              userDefined.slice().reverse().map((value, key) => {
                              const displayIndex = userDefined.length - 1 - key;
                              return <>
                              <div className="col-12 mt-3 align-middle">
                                <div className="col-lg-12 fm-card-tool p-2 align-middle d-flex flex-wrap flex-md-nowrap align-items-center">
                                    <img src={Unlock} />
                                      <div className="col-lg-10 col-md-9 col-sm-8 col-xs-12">
                                        <h1>{value.q}</h1>
                                        {value.help ? <p><i class="bi bi-info-circle"></i> {value.help}</p> : ""}
                                      </div>
                                      <div className="col-lg-1 col-xs-12 ms-auto" align="right">
                                        <img src={EditIcon} onClick={() => editUserQuestion(value.q, value.help, displayIndex, 'onecolumn')} className="close-btn" title={t('edit')} alt={t('edit')}  />
                                        <img src={CrossCircle} onClick={() => deleteUserOneQuestion(displayIndex, 'onecolumn')} className="close-btn" title={t('delete')} alt={t('delete')}  />
                                    </div>
                                  </div>
                              </div>
                              
                              </>
                              })
                            : ''}
                            
                              
                          </div>
                          
                          
                          
                          
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          
        </div>
        )
}
export default JobInstruction;