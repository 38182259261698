import {NavLink, useNavigate, useParams  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductService from "../services/ProductService";
import AssistantService from "../services/AssistantService";
import CreditBar from "./CreditBar";

import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import BarChart from './BarChart';

function AssistantOverview() {
  const { appslug } = useParams();
  const [totalProducts, setTotalProducts] = useState();
  const [totalOptimized, setTotalOptimized] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
          ProductService.ProductStats(assistresponse.assistantID).then(
           (response) => {
              console.log(response);
              setTotalProducts(response.data.total_products)
              setTotalOptimized(response.data.total_optimized)
            }

          ).catch(
            (error) => {
              console.log("Error fetching stats", error);
            }
          )  
      }
    )
    

    
    
  }, [appslug])
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap  d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('assistantoverview')}</h1>
                      <div className="breadcrumb">
                        {t('assistantoverview')}
                      </div>
                    </div>
                    <CreditBar />
                    <TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-12 px-4 ">
                    <p>{t('assistantoverviewpagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid" >
                    <div className="row"> 
                      {/*
                      <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                        <div className="fm-top-box-chart ">
                          <div className="assistantOverviewText">
                          <BarChart />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1  p-3" align="center">
                        <div className="fm-top-box-chart">
                          <div className="assistantOverviewText">
                          <BarChart />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                        <div className="fm-top-box ">
                          <div className="assistantOverviewText">
                          <h1>4125</h1>
                          products are not ready for SEO</div>
                          <br />
                          <br />
                          <NavLink to={"/"+appslug+"/completed-task"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('viewproducts')}</button> </NavLink>
                        </div>
                      </div>
                       */}
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                        <div className="fm-top-box ">
                          <div className="assistantOverviewText">
                          <h1>{totalProducts}</h1>
                          {t('seoreadyroduct')}</div>
                          <br />
                          <br />
                          <NavLink to={"/"+appslug+"/incompleted-task"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('seeallproducts')}</button> </NavLink>
                        </div>
                      </div>
                       
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                        <div className="fm-top-box ">
                          <div className="assistantOverviewText">
                          <h1>{totalOptimized}</h1>
                          {t('optmizedproducts')}</div>
                          <br />
                          <br />
                          <NavLink to={"/"+appslug+"/completed-task"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('seeseooptimizedproducts')}</button> </NavLink>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                          <div className="fm-top-box ">
                            <div className="assistantOverviewText">
                            <h1>{totalProducts-totalOptimized}</h1>
                            {t('seonotreadyroduct')}</div>
                            <br />
                            <br />
                            <NavLink to={"/"+appslug+"/incompleted-task"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('seeunoptimizedproducts')}</button> </NavLink>
                          </div>
                        </div>
                      </div>
                  </div>
                  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default AssistantOverview;